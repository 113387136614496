/**
 * @file Error 404 page content.
 * @author Martin Danhier
 * @version 1.0.0
 */

import { Typography, Grid } from "@material-ui/core";
import React from 'react';

/**
 * Page displayed in an invalid route.
 * @extends {React.Component}
 * @param {classes=} classes JSS classes for this component. 
 */
class Error404PageContent extends React.Component {

    /**
    * Component render method.
    * @public
    */
    render() {
        return (
            <>
                <Grid className={this.props.classes.root}>
                    <Typography variant="h2" >Erreur 404</Typography>
                    <Typography variant="h5">La page que vous essayez d'atteindre n'a pas été trouvée.</Typography>
                </Grid>
            </>
        );
    }
}

export default Error404PageContent;