/**
 * @file Tools to manage the authentication to the backend
 * @author Martin Danhier
 * @version 2.1.0
 */

import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Cookies from 'js-cookie';
import { API_URL_BASE, API_CHECK_CONNECTED, API_RECONNECT_ADMIN, API_CONNECT_ADMIN, STAY_CONNECTED_DURATION } from './constants';
import { addDays } from 'date-fns';
import LoadingPage from './pages/loading';

// === AUTH ===

/**
 * Object that manages the connection to the API
 * @constant
 */
export const auth = {
  /**
   * `true` if the user is connected to the API.
   */
  isAuthenticated: false,

  /**
   * Send a request to the API to test if the user is authenticated.
   * @returns {Promise<{error: boolean, id: number, cause:string, connected:boolean}>} :
   * - ``error``: true if there is an error. If true, ``id`` and ``cause`` fields will be present. If false, ``connected`` field will be present.
   * - ``id``: id of the error. (0 -> request error, 1 -> invalid request status)
   * - ``cause`` : description of the error
   * - ``connected`` : is the user connected or not
   * @public
   * @async
   */
  async test() {
    const token = Cookies.get("token");
    if (token !== undefined && token !== "undefined") {
      let response;

      // Request to the API
      try {
        response = await fetch(API_URL_BASE + API_CHECK_CONNECTED, {
          mode: 'cors',
          method: 'post',
          headers: {
            "Content-Type": "application/json",
          },
          body: `{"token": "${token}"}`,
        });
      }
      // Request error
      catch (error) {
        console.error(error);
        return { error: true, id: 0, cause: "Erreur : impossible de se connecter au serveur. Si le problème persiste, veuillez contacter l'administrateur." };
      }
      // Connected
      if (response.status === 200) {
        auth.isAuthenticated = true;
        return { error: false, connected: true };
      }
      // Not connected
      else if (response.status === 401) {
        auth.isAuthenticated = false;
        return { error: false, connected: false };
      }
      // Other thing
      else {
        console.error("[Login test] Invalid status received.");
        console.log(response);
        console.log(await response.json());
        return { error: true, id: 1, cause: "Invalid status received." };
      }
    }
    // No cookie
    else {
      auth.isAuthenticated = false;
      return { error: false, connected: false };
    }

  },

  /**
   * Sign in
   * @param {{username: string, password: string}} userdata user credentials
   * @returns {Promise<{error: boolean, id: number, cause:string}>} :
   * - ``error``: true if there is an error. If true, ``id`` and ``cause`` fields will be present.
   * - ``id``: id of the error. (0 -> request error, 1 -> invalid credentials, 2 -> bad status code)
   * - ``cause`` : description of the error
   * @public
   * @async
   */
  async signin(userdata, stayConnected = false) {

    // Request to the API
    let response;
    try {
      response = await fetch(`${API_URL_BASE}${API_CONNECT_ADMIN}`, {
        method: 'post',
        mode: 'cors',
        body: JSON.stringify(userdata),
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }
      });
    }
    // Error in request
    catch (error) {
      console.error(error);
      return { error: true, id: 0, cause: "Erreur : impossible de se connecter au serveur. Si le problème persiste, veuillez contacter l'administrateur." };
    }

    // Connected
    if (response.status === 200) {
      // Get data
      const responseBody = await response.json();
      // Save access cookie
      Cookies.set("token", responseBody.access, {
        // secure: true, 
      });

      if (stayConnected) {
        // Save refresh cookie with a duration
        Cookies.set("refresh_token", responseBody.refresh, {
          expires: addDays(Date.now(), STAY_CONNECTED_DURATION),
          // secure: true, 
        });
      }
      else {
        // Save refresh cookie for the session
        Cookies.set("refresh_token", responseBody.refresh, {
          // secure: true, 
        });
      }

      // Save auth status and return
      auth.isAuthenticated = true;
      return { error: false };
    }
    // Not connected
    else if (response.status === 401) {
      return { error: true, id: 1, cause: "Invalid credentials." };
    }
    else {
      return { error: true, id: 2, cause: "Bad status code." };
    }
  },

  /**
   * Refresh : log in without credentials with refresh cookie.
   * @returns {Promise<{error: boolean, id: number, cause:string}>} :
   * - ``error``: true if there is an error. If true, ``id`` and ``cause`` fields will be present.
   * - ``id``: id of the error. (0 -> request error, 1 -> invalid refresh token, 2 -> no refresh token cookie)
   * - ``cause`` : description of the error
   * @public
   * @async
   */
  async refresh() {

    const refreshToken = Cookies.get("refresh_token");
    if (refreshToken !== undefined && refreshToken !== "undefined") {
      // Request to the API
      let response;
      try {
        response = await fetch(API_URL_BASE + API_RECONNECT_ADMIN, {
          mode: 'cors',
          method: 'post',
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
          },
          body: `{"refresh": "${refreshToken}"}`,
        });
      }
      // Error in request
      catch (error) {
        console.error(error);
        return { error: true, id: 0, cause: "Erreur : impossible de se connecter au serveur. Si le problème persiste, veuillez contacter l'administrateur." };
      }

      // Connected
      if (response.status === 200) {
        // Save access cookie
        const responseBody = await response.json();
        Cookies.set("token", responseBody.access, {
          // secure: true,
        });

        // Save auth status and return
        auth.isAuthenticated = true;
        return { error: false };
      }
      // Not connected
      else if (response.status === 401) {
        return { error: true, id: 1, cause: "Refresh token invalid or expired." };
      }
      // No refresh token
    } else {
      return { error: true, id: 2, cause: "Refresh token undefined." }
    }
  },

  /**
   * Sign out : change the value of ``this.auth`` to ``false``
   */
  signout() {
    auth.isAuthenticated = false;
    Cookies.remove("token");
    Cookies.remove("refresh_token");
    Cookies.remove("sessionid");
    Cookies.remove("csrftoken");
  }
}

// === PRIVATE ROUTE ===


/**
 * Route that redirects to the login page if the user is not connected.
 * @extends React.Component
 */
export class PrivateRoute extends React.Component {

  /**
   * Component render method.
   * @public
   */
  render = () => {
    return <AuthLoader
      onDisconnected={(props) => <Redirect to="/login" />}
      onConnected={(props) => {
        // Destructure props
        let { render, component, ...rest } = this.props;

        return <Route
          {...rest}
          render={props =>
            this.props.component === undefined ? this.props.render(props) : <this.props.component {...props} />
          }
        />;
      }}
    />;
  }
}

// === AUTH LOADER ===

/**
 * Wrapper component for other components: test if the user is connected and
 * call two different callbacks according to the result.
 * 
 * @augments React.Component
 * @public
 */
export class AuthLoader extends React.Component {

  /**
   * @constructor
   * @param {*} props React props passed to this Component.
   * @public
   */
  constructor(props) {
    super(props);
    /**
     * State of the component
     * @private
     */
    this.state = {
      // Is the route waiting for the auth status ?
      loading: true,
      // If non empty, the error will be diplayed in a snack bar
      error: ""
    };

    // Get the status
    this.getAuthStatus();

  }

  /**
   * Get the auth status and update state.
   * @async
   * @private
   */
  getAuthStatus = async () => {
    let newState;
    let result;
    while (this.state.loading) {
      // Test if connected
      result = await auth.test();

      // Copy state
      newState = Object.assign({}, this.state);
      // Error
      if (result.error) {
        // Diplay error
        newState.error = result.cause;
        this.setState(newState);
        // Sleep for 10s then retry
        await new Promise(resolve => setTimeout(resolve, 10000));
      }
      // Success
      else {
        let shouldRedirect = true;

        // Try to refresh the connection
        if (!result.connected) {
          const refreshResult = await auth.refresh();
          if (refreshResult.error === true && refreshResult.id === 0) {
            newState.error = refreshResult.cause;
            shouldRedirect = false;
            this.setState(newState);
            // Sleep for 10s then retry
            await new Promise(resolve => setTimeout(resolve, 10000));
          }
        }
        if (shouldRedirect) {
          // Stop loading and redirect
          newState.loading = false;
          this.setState(newState);
        }
      }
    }

  }

  /**
 * Component render method.
 * @public
 */
  render = () => {

    if (this.state.loading) {
      return (
        <LoadingPage error={this.state.error} />
      );

    }
    else if (auth.isAuthenticated) {
      return this.props.onConnected(this.props);
    }
    else {
      return this.props.onDisconnected(this.props);
    }

  }
}
