/**
 * @file Loading screen
 * @author Martin Danhier
 * @version 1.0.0
 */

import React from 'react';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

/**
 * A loading screen. Can display an error message if needed.
 * @extends {React.Component<{error: string}>}
 */
class LoadingPage extends React.Component {

    /**
     * Component render method.
     * @public
     */
    render() {
        // Center the items
        return (
            <div style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
            }}>
                {/* Loading screen */}
                <Typography variant="h5" style={{ padding: 20 }}>Chargement ...</Typography>
                <CircularProgress />

                {/* Snack bar indicating an error */}
                <Snackbar
                    open={this.props.error !== ""}
                    anchorOrigin={{ "horizontal": "center", "vertical": "top" }}
                >
                    <MuiAlert
                        variant="filled"
                        elevation={6}
                        severity="error" >
                        {this.props.error}
                    </MuiAlert>

                </Snackbar>
            </div>
        );
    }
}

export default LoadingPage;