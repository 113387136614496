/**
 * @file Style wrapper of a /login page.
 * @author Martin Danhier
 * @version 2.0
 */

import React from 'react';
import { makeStyles } from '@material-ui/styles';
import LoginPageContent from './login-content';
import LogoImage from './logo_ameliore.png';

/**
 * JSS style for the page.
 */
const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: `url(${LogoImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#242424',
    backgroundSize: '70%',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

/**
 * Login page.
 * @param {*} props 
 */
function LoginPage(props){
  const classes = useStyles();
  return <LoginPageContent classes={classes} {...props} />;
}
export default LoginPage;