/**
 * @file Content of a /login page.
 * @author Martin Danhier
 * @version 0.3.0
 */

import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { Redirect } from 'react-router-dom';
import Cookies from 'js-cookie';
import { addDays } from 'date-fns';

import { auth, AuthLoader } from '../../auth';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { API_URL_BASE, API_CONNECT_CLIENT, STAY_CONNECTED_DURATION } from '../../constants';

/**
 * A form designed to collect the credentials of the user
 * and connect it to the back-end.
 *  
 * @param {classes=} classes JSS classes for this component
 * @extends React.Component<Props,State>
 */
class LoginPageContent extends React.Component {

    /**
     * @constructor
     * @param {object} props React props passed to this Component.
     * @public
     */
    constructor(props) {
        super(props);

        /**
         * State of the component
         * @private
         */
        this.state = {
            // Should the page redirect to the next page ?
            // Test if the user is already connected, if so then redirect to admin
            redirect: false,
            userIsAdmin: false,
            // Errors displayed on the text fields
            errors: {
                email: "",
                password: "",
            },
            // User credentials
            userdata: {
                email: "",
                password: "",
            },
            // Stay connected check box
            stayConnected: false,
        }
    }

    /**
   * Client sign in
   * @param {{email: string, password: string}} userdata user credentials
   * @returns {Promise<{error: boolean, id: number, cause:string}>} :
   * - ``error``: true if there is an error. If true, ``id`` and ``error`` fields will be present.
   * - ``id``: id of the error. (0 -> request error, 1 -> invalid credentials, 2-> bad status code)
   * - ``cause`` : description of the error
   * @public
   * @async
   */
    connectClient = async (userdata, stayConnected) => {
        // Request to the API
        let response;
        try {
            response = await fetch(`${API_URL_BASE}${API_CONNECT_CLIENT}`, {
                method: 'post',
                mode: 'cors',
                body: JSON.stringify(userdata),
                headers: {
                    'Content-Type': 'application/json',
                }
            });
        }
        // Error in request
        catch (error) {
            console.error(error);
            return { error: true, id: 0, cause: "Erreur : impossible de se connecter au serveur. Si le problème persiste, veuillez contacter l'administrateur." };
        }

        // Connected
        if (response.status === 200) {

            // Get data
            const responseBody = await response.json();

            // Save token
            if (stayConnected) {
                // Save token cookie
                Cookies.set("token", responseBody.token, {
                    expires: addDays(Date.now(), STAY_CONNECTED_DURATION),
                    // secure: true, 
                });
                // Save id cookie
                Cookies.set("client_id", responseBody.id, {
                    expires: addDays(Date.now(), STAY_CONNECTED_DURATION),
                    // secure: true,
                });
            }
            else {
                // Save token cookie
                Cookies.set("token", responseBody.token, {
                    // secure: true,
                });
                // Save id cookie
                Cookies.set("client_id", responseBody.id, {
                    // secure: true,
                });
            }

            return { error: false };
        }
        // Not connected
        else if (response.status === 401) {
            return { error: true, id: 1, cause: "Invalid credentials." };
        }
        else {
            return { error: true, id: 2, cause: "Bad status code." };
        }
    }

    /**
     * Send the credentials to the API to log in.
     * @param {*} event Form validation event
     * @async
     * @private
     */
    handleSubmit = async (event) => {
        // Disable html5 form validation
        event.preventDefault();

        let newState = Object.assign({}, this.state);

        // Check email
        if (this.state.userdata.email === "") {
            // Error: a value must be provided
            newState.errors.email = "Ce champ est obligatoire.";
        }
        // Check password
        if (this.state.userdata.password === "") {
            // Error: a value must be provided
            newState.errors.password = "Ce champ est obligatoire.";
        }
        this.setState(newState);

        // If the input is valid, submit the data to the API
        if (newState.userdata.password !== "" && newState.userdata.email !== "") {

            let result;

            // Try to connect as admin
            result = await auth.signin({
                username: this.state.userdata.email,
                password: this.state.userdata.password,
            }, this.state.stayConnected);

            // Check if it worked
            if (result.error) {
                // Error 0: request error
                if (result.id === 0) {
                    // Show a error message in the UI
                    let newState = Object.assign({}, this.state);
                    newState.errors.password = "Erreur interne : impossible de se connecter au serveur.";
                    newState.userdata = { email: "", password: "" };
                    this.setState(newState);
                    return;
                }
                // Error 1: invalid credentials
                else if (result.id === 1) {
                    // It didn't work; try to connect as client
                    result = await this.connectClient(this.state.userdata, this.state.stayConnected);

                    // Copy the state
                    let newState = Object.assign({}, this.state);
                    newState.userdata.password = "";

                    // Check if it worked
                    if (result.error) {

                        // Error 0: request error
                        if (result.id === 0) {
                            // Show a error message in the UI
                            newState.errors.password = "Erreur interne : impossible de se connecter au serveur.";
                        }
                        // Error 1: invalid credentials
                        else if (result.id === 1) {
                            // Invalid credentials; display error.
                            newState.errors.password = "Le nom d'utilisateur ou le mot de passe que vous avez entré est incorrect.";
                        }
                    }
                    // Successfully connected as client !
                    else {
                        // Redirect to the earnings page
                        newState.redirect = true;
                        newState.userIsAdmin = false;
                    }

                    this.setState(newState);
                }

            }
            // Successfully connected as admin !
            else {
                // Redirect to the dashboard
                let newState = Object.assign({}, this.state);
                newState.redirect = true;
                newState.userIsAdmin = true;
                this.setState(newState);
            }

        }
    }

    /**
     * Whenever the form is updated, update the state.
     * @param {*} event Form update event
     * @private
     */
    handleChange = (event) => {

        // Copy the state
        let newState = Object.assign({}, this.state);

        // Apply the change
        newState.userdata[event.target.id] = event.target.value;

        // Remove error if non empty
        if (event.target.value !== "") {
            newState.errors[event.target.id] = "";
        }

        // Replace the state with the new one
        this.setState(newState);
    }

    /**
     * Whenever the form is updated, update the state.
     * @param {*} event Checkbox update event
     * @private
     */
    handleCheckBoxChange = (event) => {

        // Copy the state
        let newState = Object.assign({}, this.state);

        // Toggle the value
        newState.stayConnected = !this.state.stayConnected;

        // Replace the state with the new one
        this.setState(newState);
    }

    /**
     * Component render method.
     * @public
     */
    render = () => {

        // If the user should redirect to the dashboard
        // Redirect to dashboard
        if (this.state.redirect) {

            if (this.state.userIsAdmin) {
                if (this.props.location.state !== "/client") {
                    let { from } = this.props.location.state || { from: { pathname: "/admin" } };
                    return <Redirect to={from} />
                }
                else {
                    return <Redirect to="/admin" />;
                }
            }
            else {
                return <Redirect to="/client" />;
            }
        }

        // Design of the page
        return (
            <AuthLoader
                onConnected={(props) => {
                    let { from } = this.props.location.state || { from: { pathname: "/admin" } };
                    console.log(from);
                    return <Redirect to={from} />;
                }}
                onDisconnected={(props) =>
                    <Grid container component="main" className={this.props.classes.root}>
                        <Grid item xs={false} sm={4} md={7} className={this.props.classes.image} />
                        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                            <div className={this.props.classes.paper}>

                                {/* Icon */}
                                <Avatar className={this.props.classes.avatar}>
                                    <LockOutlinedIcon />
                                </Avatar>

                                {/* Title */}
                                <Typography component="h1" variant="h5">
                                    Se connecter
                        </Typography>

                                {/* The form use noValidate to disable html5 form validation */}
                                <form className={this.props.classes.form} noValidate onSubmit={(event) => { this.handleSubmit(event); }}>

                                    {/* User name field */}
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        onChange={this.handleChange}
                                        id="email"
                                        label="Adresse e-mail"
                                        name="email"
                                        autoComplete="email"
                                        value={this.state.userdata.email}
                                        error={this.state.errors.email !== ""}
                                        helperText={this.state.errors.email}
                                    />

                                    {/* Password field */}
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        onChange={this.handleChange}
                                        name="password"
                                        label="Mot de passe"
                                        type="password"
                                        id="password"
                                        autoComplete="current-password"
                                        value={this.state.userdata.password}
                                        error={this.state.errors.password !== ""}
                                        helperText={this.state.errors.password}
                                    />

                                    {/*Stay connected checkbox*/}
                                    <FormControlLabel
                                        control={<Checkbox value={this.state.stayConnected} onChange={this.handleCheckBoxChange} />}
                                        label="Rester connecté."
                                    />

                                    {/* Submit button */}
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        className={this.props.classes.submit}
                                    >
                                        Se connecter
                            </Button>
                                </form>
                            </div>
                        </Grid>
                    </Grid>} />
        );
    }
}
export default LoginPageContent;