/**
 * @file Constants used across the project.
 * @author Martin Danhier
 * @version 0.2
 */

// Declare in this file the different constants.
// For instance, the URL of the API and its different routes.

// ======= API =======

/**
 * Root directory of the project.
 * @constant
 * @type {string}
 */
export const API_URL_BASE = "https://api.store.brocanton.be";

/**
 * Get a parameter
 * @constant
 * @type {string}
 */
export const API_GET_PARAMETER = "/client/get-parameter/";

/**
 * Get a parameter
 * @constant
 * @type {string}
 */
export const API_SET_PARAMETER = "/client/set-parameter/";

/**
 * Route to connect the admin user.
 * @constant
 * @type {string}
 */
export const API_CONNECT_ADMIN = "/api/token/";

/**
 * Route to check if the admin user is connected.
 * @constant
 * @type {string}
 */
export const API_CHECK_CONNECTED = "/api/token/verify/";

/**
 * Route to reconnect the admin if he has a refresh token.
 * @constant
 * @type {string}
 */
export const API_RECONNECT_ADMIN = "/api/token/refresh/";

/**
 * Route to add a product to a shelf.
 * @constant
 * @type {string}
 */
export const API_ADD_PRODUCT = "/product/add-product/";

/**
 * Route to add several products at once
 * @constant
 * @type {string}
 */
export const API_ADD_SEVERAL_PRODUCTS = "/product/advanced-products/";


/**
 * Route to generate the transactions document.
 * @constant
 * @type {string}
 */
export const API_MAKE_DOC = "/sale/make-document/";

/**
 * Route to generate the wiretransfers CSV.
 * @constant
 * @type {string}
 */
 export const API_WIRETRANSFER_CSV = "/sale/make-wiretransfer/";

/**
 * Route to generate the product price history CSV.
 * @constant
 * @type {string}
 */
 export const API_PRICE_CSV = "/product/make-price/";

/**
 * Route to generate the locations CSV.
 * @constant
 * @type {string}
 */
 export const API_LOCATION_CSV = "/sale/make-location-csv/";

/**
 * Route to get detailed data from a registered client.
 * @constant
 * @type {string}
 */
export const API_GET_CLIENT = "/client/get-client/";

/**
 * Route to get data from registered clients.
 * @constant
 * @type {string}
 */
export const API_GET_CLIENTS = "/client/get-clients/";

/**
 * Route to add a client.
 * @constant
 * @type {string}
 */
export const API_ADD_CLIENT = "/client/add-client/";

/**
 * Route to update a client.
 * @constant
 * @type {string}
 */
export const API_UPDATE_CLIENT = "/client/update-client/";

/**
 * Route to get the information about a product
 * @constant
 * @type {string}
 */
export const API_GET_PRODUCT = "/product/get-product/";

/**
 * Route to get the available shelves
 * @constant
 * @type {string}
 */
export const API_GET_SHELVES = "/sale/get-shelves/";

/**
 * Route to reset the gains of a client
 * @constant
 * @type {string}
 */
export const API_RESET_CLIENT_GAINS = "/client/rembourser-client/";

/**
 * Route to make a sale
 * @constant
 * @type {string}
 */
export const API_MAKE_SALE = "/sale/make-sale/";

/**
 * Route to get the amount of money made by a client
 * @constant
 * @type {string}
 */
export const API_GAIN_CLIENT = "/client/gain-client/";

/**
 * Route to connect a client to the backend.
 * @constant
 * @type {string}
 */
export const API_CONNECT_CLIENT = "/client/connect-client/";

/**
 * Route to get the total amount of money the client earned.
 * @constant
 * @type {string}
 */
export const API_INFO_CLIENT = "/client/info-client/";

/**
 * Route to anonymize the user data
 * @constant
 * @type {string}
 */
export const API_ANONYMIZE_CLIENT = "/client/anonymize-client/";

/**
 * Route to get the price of a product.
 * @constant
 * @type {string}
 */
export const API_GET_PRICE = "/product/get-price/";

/**
 * Route to set the price of a product.
 * @constant
 * @type {string}
 */
export const API_UPDATE_PRICE = "/product/update-price/";

/**
 * Route to get the list of available implantations.
 * @constant
 * @type {string}
 */
export const API_GET_IMPLANTATIONS = "/sale/info-all-implantation/";

/**
 * Route to return a product
 * @constant
 * @type {string}
 */
export const API_RETURN_PRODUCT = "/product/make-return/";


// ======= PROJECT =======

/**
 * Root directory of the project.
 * @constant
 * @type {string}
 * @todo Replace with the actual directory on the production server,
 * else the template won't be found.
 */
export const PROJECT_ROOT = "C:/Users/User/Documents/Etiquettes";

/**
 * Path to the different sticker templates based on the media id of the sticker model in bpac
 * @constant
 */
export const STICKER_TEMPLATES = {
    product: {
        // 29mm continuous : prod template
        258: { 
            name: "Rouleau continu d'étiquettes 29mm",
            path: "/format_rouleau.lbx" 
        },
        // 62mm continuous : prod template
        259: {
            name: "Rouleau continu d'étiquettes 62mm",
            path: "/template_62mm_continu.lbx"
        },
        // 62mm x 29mm separated : prod template
        274: { 
            name: "Étiquettes prédécoupées 62x29mm",
            path: "/template_separated.lbx",
        },
    },
    client: {
        258: {
            name: "Rouleau continu d'étiquette 29mm",
            path: "/format_rouleau_client.lbx"
        }
    }  
}

/**
 * Duration, in days, of a "stay connected" session.
 * @constant
 * @type {number}
 */
export const STAY_CONNECTED_DURATION = 15

// ======= DEBUG OPTIONS =======

/**
 * If ``true``, the print button will try to connect to the printing SDK.
 * Can be disabled for debugging purposes, but must be enabled in production.
 * @constant
 * @type {boolean}
 * @default true
 */
export const ENABLE_PRINTING = true;

// ======= DESIGN =======

/**
 * Width of the dashboard drawer
 * @type {number}
 * @constant
 */
export const DRAWER_WIDTH = 280;
