/** 
 * Root file of the application.
 * @author Martin Danhier
 * @version 1.0.0
 */

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import CssBaseline from '@material-ui/core/CssBaseline';

import Error404Page from './pages/error-404/error-404';
import LoginPage from './pages/login/login';
import LoadingPage from './pages/loading';
import { PrivateRoute } from './auth';

// Code splitting (only send the user the pages they need)
const AdminPage = React.lazy(() => import('./pages/admin/admin'));
const ClientPage = React.lazy(() => import('./pages/client/client'));

/**
 * Theme of the website.
 */
let theme = createMuiTheme({
  palette: {
    divider: '#9fa3a6',
    success: {
      main: "#27ae60"
    },
    info: {
      main: "#3498db"
    },
    error: {
      light: "#ed6f6d",
      main: "#f0302e",
      dark: "#d32f2f"
    },
    warning: {
      light: "#ffb74d",
      main: "#e67e22",
      dark: "#f57c00"
    },
    primary: {
      main: '#37474f',
      light: '#62727b',
      dark: '#102027'
    },
    secondary: {
      main: '#616161'
    }
  },

  status: {
    danger: 'orange',
  },
});
theme = responsiveFontSizes(theme);

// Main render function
ReactDOM.render(
  // Add the theme
  <ThemeProvider theme={theme}>
    {/* CSS baseline (must-have classes) */}
    <CssBaseline />
    {/* Declare the routes */}
    <Router>
      <Switch>
        <Route exact path="/" render={(props) => <Redirect to="/login" />} />
        <Route path="/login" component={LoginPage} />
        <Route path="/client" render={(props) => <Suspense fallback={<LoadingPage error="" />}><ClientPage {...props} /></Suspense>} />
        {/* Private route ensures that the user is connected. If it is not, redirect to /login. */}
        <PrivateRoute path="/admin" render={(props) => <Suspense fallback={<LoadingPage error="" />}><AdminPage {...props} /></Suspense>} />
        <Route render={(props) => (
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
            style={{ minHeight: '100vh' }}>
            <Error404Page />
          </Grid>
        )} />
      </Switch>
    </Router>
  </ThemeProvider>
  , document.getElementById('root'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
