/**
 * @file Style wrapper of an Error 404 page.
 * @author Martin Danhier
 * @version 1.0.0
 */

import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Error404PageContent from './error-404-content';

/**
 * JSS style for the page.
 */
const useStyles = makeStyles(theme => ({
  root:{
    textAlign:"center"
  }
}));

/**
 * Page displayed in an invalid route.
 * @param {*} props 
 */
function Error404Page(props) {
  const classes = useStyles();
  return <Error404PageContent classes={classes} {...props} />;
}

export default Error404Page;